<template>
    <div>
        <div class="col d-sm-flex justify-content-center">
            <div class="card text-center">
                <div v-if="activated">
                    <h2>Grazie per aver attivato la tua licenza</h2>
                    <p>Ora puoi andare nella tua area prodotti e cliccare sul prodotto per visualizzarlo</p>
                    <router-link class="btn btn-primary btn-lg" to="/products">Vai ai tuoi prodotti</router-link>
                </div>
                <div v-else-if="license">
                    <h1>{{ license.template.name }}</h1>
                    <br>
                    <div v-html="license.template.termsOfUse" class="collapsed-terms terms-of-use" ></div>
                    <br>
                    <b-form-checkbox
                        id="acceptedTerms"
                        v-model="license.acceptedTerms"
                        name="acceptedTerms"
                        class="checkbox-terms"
                        :state="validateState('acceptedTerms')"
                        aria-describedby="acceptedTerms-live-feedback">
                        Dichiaro di accettare tutte le condizioni genarali d'uso
                    </b-form-checkbox>
                    <br>

                    <b-button variant="success" size="lg" block @click="activateLicense" :disabled="this.$store.state.loading || !license.acceptedTerms">ATTIVA</b-button>
                </div>
                <div v-else>
                    <h3>Inserisci il codice di attivazione</h3>
                    <b-form-group id="code" label="" label-for="code" description="">
                        <b-form-input v-model.trim="code" class="code" autofocus></b-form-input>
                    </b-form-group>
                    <br/>
                    <b-button variant="success" size="lg"  @click="retrieveLicense" :disabled="this.$store.state.loading">ATTIVA</b-button>
                </div>

                <div class="text-center">
                    <br>
                    <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import moment from 'moment';
import { required, sameAs } from 'vuelidate/lib/validators'
import EduLabDataService from "@/components/product_license_template/EduLabDataService";
import {ParseUrl} from "@/helpers/ParseUrl";

export default {
    name: "product-license-activate",

    data() {
        return {
            code: null,
            license: null,
            activated: false,
            products: [],
            query: '',
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        license: {
            acceptedTerms: {
                required,
                sameAs: sameAs( () => true )
            },
        },
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.license[name];
            return $dirty ? !$error : null;
        },
        retrieveLicense() {
            ProductLicenseDataService.getByCode(this.code.trim())
                .then(response => {
                    if (response.data.activated && response.data.profileId) this.$router.push('/products'); // in realtà qui potrei pensare di fare il redirect ad edulab
                    this.license = response.data;
                    this.message = '';
                    this.errorMsg = '';
                })
                .catch(e => {
                    this.errorMsg = "Nessuna licenza corrispondente!"
                    console.log(e);
                });
        },
        activateLicense() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                this.license.profileId = this.$store.state.user.profileId;
                ProductLicenseDataService.activate(this.license.id, this.license)
                    .then(response => {
                        const parsedUrl = ParseUrl();
                        if (parsedUrl.queryParams.has('edulabProductId')) {
                            let schoolId = this.license.schoolId;
                            // redirect to edulab resource
                            location.href = EduLabDataService.edulabBaseUrl + parsedUrl.query + '&schoolId='+schoolId + '&token='+this.$store.state.token;
                        }
                        this.activated = true;
                        this.message = '';
                        this.errorMsg = '';
                    })
                    .catch(e => {
                        this.errorMsg = e.response.data.message;
                        console.log(e);
                    });
            }
        },

        formatDate(datetime) {
            return moment(datetime).format('DD/MM/YYYY HH:mm');
        },
        isExpired() {
            return (moment().isAfter(this.license.expireAt));
        }
    },
    mounted() {
        this.message = '';
        if (this.$route.params.code) {
            this.code = this.$route.params.code;
            this.retrieveLicense();
        }

        EduLabDataService.getAllProducts()
            .then(response => response.json())
            .then(data => {
                this.products = data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
.collapsed-terms {
    height: 340px;
    overflow-y: auto;
}
.terms-of-use {
    padding: 15px;
    border: 1px solid #eee;
    text-align: left;
}

#code {
    display: inline-block;
    margin: 30px;
}
.code {
    font-size: 40px;
    width: 6em;
    text-align: center;
}
.code, .code:focus {
    background-color: #fdf4d7;
    border: 2px solid #f8e4a4;
}
.checkbox-terms {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
}
</style>
